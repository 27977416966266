<template> 
<!-- アーティスト -->
  <v-container class="pt-3">
    <v-card-text>
    <v-row
      v-for="artist in artists"
      v-bind:key="artist.id"
      >
      <v-col sm="5" cols="12">
        <a :href="artist.url">
          <v-img :src="artist.thumbnail" />
        </a>
      </v-col>
      <v-col sm="7" cols="12">
        <v-card-title> {{ artist.name }} </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-pre-wrap"> {{ artist.summary }} </v-card-text>
      </v-col>
    </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

export default {
  name: 'EventArtists',
  props: {
    contentId: Number,
  },
  data(){
    return{
      artists: []
    }
  },
  watch: {
    async contentId(){
      await this.loadArtists()
    },
    async $route(){
      await this.loadArtists()
    }
  },
  async mounted(){
    this.loadArtists()
  },
  methods: {
    async loadArtists(){
      if(!this.contentId) return
      this.artists = await this.wc.get('artist', `w/content_id,${this.contentId}:is_enabled,1`).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style>

</style>
