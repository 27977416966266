<template>
  <div>
    <v-app-bar 
      color="primary px-5"
      dark
      dense
      class="text-caption"
    >
    <v-row>
      <p class="ma-0">
        <router-link
          :to="{name: 'event', params: {id: eventId}}"
          class="white--text"
          style="text-decoration:none;"
        >
          <v-icon small style="margin-top:-2px;">mdi-arrow-left</v-icon> 
          イベントページに戻る
        </router-link>
      </p>
    <v-spacer/>
      <p class="ma-0">
        {{ eventTitle }}&nbsp;/&nbsp;{{ contentTitle }}
      </p>
     </v-row>
    </v-app-bar>
    <v-container fluid>
      <ContentComponent :contentId="contentId" />
      <v-container class="mt-12">
        <v-card>
          <v-card-title class="primary white--text" >
            {{ event.title }}
          </v-card-title>
          <v-card-text>
            <p class="text-center ma-6">
              <v-btn :to="{ name: 'event', params:{ id: eventId }}">
                イベントページに戻る
              </v-btn>
            </p>
            <EventContentList :eventId="eventId" />
          </v-card-text>
        </v-card>
      </v-container>
      <StoneFooter :isMobile="isMobile" />
    </v-container>
  </div>
</template>
<script>
import ContentComponent from '@/components/organisms/Content'
import EventContentList from '@/components/organisms/EventContentList'
import StoneFooter      from '@/components/organisms/StoneFooter'

export default{
  name: 'Content',
  data(){
    return{
      eventId: this.$route.params.id,
      contentId: Number(this.$route.params.content_id),
      event: {},
      content: {},
      isMobile: false
    }
  },
  computed:{
    eventTitle(){
      if(!this.event || !this.event.title) return ''
      return this.event.title.length > 20 ? this.event.title.substr(0,20)+'...' : this.event.title
    },
    contentTitle(){
      if(!this.content || !this.content.title) return ''
      return this.content.title.length > 20 ? this.content.title.substr(0,20)+'...' : this.content.title
    }
  },
  props:{
    getBuy: Boolean
  },
  watch:{
    async $route(){
      this.eventId = this.$route.params.id
      this.contentId = Number(this.$route.params.content_id)
      await this.loadData()
    }
  },
  components: {
    ContentComponent,
    EventContentList,
    StoneFooter,
  },
  async mounted(){
    await this.loadData()
  },
  async created(){
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed(){
    window.removeEventListener('resize', this.handleResize)
  },
  methods:{
    async loadData(){
      const eventContents = await this.wc.get('event_content', `w/event_id,${this.$route.params.id}:content_id,${this.$route.params.content_id}`).catch((err) => {
        console.log(err)
      })
      if(eventContents.length == 0 || !this.getBuy){
        this.$router.push({name:'event', params: this.$route.params.id})
        return
      }
      [this.event] = await this.wc.get('v_event', `w/id,${this.$route.params.id}`).catch((err) => {
        console.log(err)
      });
      [this.content] = await this.wc.get('v_content', `w/id,${this.$route.params.content_id}`).catch((err) => {
        console.log(err)
      })
      
    },
    handleResize(){
      this.isMobile = window.innerWidth < 960 ? true : false
    }
  }
}
</script>
<style>

</style>
