<template>
  <v-card>
    <!-- サムネイル or ビデオ -->
    <!-- TODO: LocalStorageの判定で良いか。ユーザー側で書き換えてしまえない？ -->
    <content-video
      v-if="content.show_video && getBuy"
      :content="content"
      :encoderId="content.encoder_id"
    />
    <content-thumbnail
      v-else
      :contentId="contentId"
    />
    <!-- サムネイル or ビデオここまで -->
    <v-row>
      <v-col md="9" sm="12" cols="12">
        <content-detail :content="content" :clientsCount="clientsCount" />
        <content-artists :contentId="contentId" />
      </v-col>
      <v-col md="3" sm="12" cols="12">
        <content-message
          ref="contentMessage"
          :contentId="contentId"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import socket from '@socket/client'

import ContentVideo from '@/components/organisms/ContentVideo'
import ContentThumbnail from '@/components/organisms/ContentThumbnail'
import ContentDetail from '@/components/organisms/ContentDetail'
import ContentArtists from '@/components/organisms/ContentArtists'
import ContentMessage from '@/components/organisms/ContentMessage'

export default{
  name: 'EventContent',
  data(){
    return{
      content:{},
      clientsCount: 0,
      ws: null
    }
  },
  props:{
    contentId: Number
  },
  computed:{
    ...mapGetters(['getBuy', 'getHash'])
  },
  watch:{
    async $route(){
      await this.loadContent()
      await this.connectWebsocket()
    }
  },
  async mounted(){
    await this.loadContent()
    await this.connectWebsocket()
  },
  methods:{
    async loadContent(){
      if(!this.contentId) return
      [this.content] = await this.wc.get('v_content',`w/id,${this.contentId}`).catch((err) => {
        console.log(err)
      })
      this.clientsCount = this.content.current_connection
    },
    async connectWebsocket(){
      // WebSocket
      if(this.ws) this.ws.close()
      if(!this.content.is_ended){
        const username = this.getHash ? this.getHash : 'notpay'
        const socketUrl = `${this.config.ws}content_${this.content.id}/${username}/type:main`
        this.ws = await socket.client(socketUrl).catch((err) => {
          console.log(err)
        })
        this.ws.on('message', this.onMessage)
      }
    },
    async onMessage(message){
      console.log(message)
      // 視聴人数カウント
      if("clients_count" in message){
        this.clientsCount = message['clients_count']
      }
      // 主催者投稿メッセージ
      if("message_id" in message){
        this.$refs.contentMessage.loadMessage()
      }
      // 配信ステータス
      if("status" in message){
        this.loadContent()
      }
    }
  },
  beforeDestroy(){
    if(this.ws) this.ws.close()
  },
  components: {
    ContentVideo,
    ContentThumbnail,
    ContentDetail,
    ContentArtists,
    ContentMessage
  }
}
</script>
<style>

</style>
