<template>
  <v-container>
    <v-card-subtitle>
      <template v-if="content.is_on_live && content.current_connection >= 0">
        {{ clientsCount }}人が視聴中
      </template>
      <template v-if="!content.is_begun">
        {{ countDownMessage }}
      </template>
      <template v-if="content.content_status_property_name == 'onPrepareArchive'">
        ただいまアーカイブ配信の準備中です。
      </template>
      <template v-if="content.is_ended">
        このコンテンツの配信は終了しました。
      </template>
    </v-card-subtitle>
    <v-card-title>{{ content.title }}</v-card-title>
    <v-card-text>
      {{ formatDatetime(content.begin_at) }} - {{ formatDatetime(content.end_at) }}
    </v-card-text>
    <v-card-text class="text-pre-wrap">{{ content.summary }}</v-card-text>
  </v-container>
</template>
<script>
import moment from 'moment'
import datetimeLib from '@/lib/datetime'

export default{
  name: 'ContentDetail',
  data(){
    return{
      timer: null,
      countDownMessage: '',
    }
  },
  props:{
    content: Object,
    clientsCount: Number
  },
  mounted(){
    this.timer = setInterval(this.updateCountDown, 100)
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  methods:{
    formatDatetime(dt){
      if(!dt) return
      return datetimeLib.dispDate(dt)
    },
    updateCountDown(){
      if(!this.content) return

      let message = datetimeLib.generateCountDown(this.content.begin_at)
      const now = moment().format('YYYY-MM-DD HH:mm:ss')
      if(moment(this.content.begin_at).isSameOrBefore(now)){
        message = '開始までしばらくお待ちください'
      }
      this.countDownMessage = message
    },
  }
}
</script>
<style></style>
