<template>
  <div>
    <v-carousel 
      hide-delimiters
      v-model="selectedThumbnail"
      :show-arrows="thumbnails.length > 1"
      height="auto"
      cycle
    >
      <v-carousel-item
        v-for="thumbnail in thumbnails" :key="thumbnail.id"
      >
        <v-img 
          :src="thumbnail.url"
          :aspect-ratio="16/9"
          contain
        />
      </v-carousel-item>
    </v-carousel>
<!--
    <v-slide-group
      v-model="selectedThumbnail"
      class="black"
    >
      <v-slide-item
        v-for="thumbnail in thumbnails"
        :key="thumbnail.id"
        v-slot="{ active, toggle }"
      >
        <v-sheet
          @click="toggle"
          height="100"
          width="100"
          :class="{ activeThumbnail: active, nonActiveThumbnail: !active }"
        >
          <v-img
            height="100"
            :src="thumbnail.url"
          />
        </v-sheet>
      </v-slide-item>
    </v-slide-group>
-->
  </div>
</template>
<script>
export default{
  name:'ContentThumbnail',
  data(){
    return{
      thumbnails: [],
      selectedThumbnail: 0
    }
  },
  props:{
    contentId: Number
  },
  async mounted(){
    await this.loadThumbnail()
  },
  watch:{
    contentId: async function(){
      await this.loadThumbnail()
    }
  },
  methods:{
    async loadThumbnail(){
      this.thumbnails = await this.wc.get('content_thumbnail', `w/content_id,${this.contentId}`).catch((err) => {
        console.log(err)
      })
      this.thumbnails = this.thumbnails.length > 0 ? this.thumbnails : [{ id: 0, url: this.config.other.event_thumbnail}]
    }
  }
}
</script>
<style scoped>
.activeThumbnail{
  position: relative;
}
.activeThumbnail::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 3px solid #ffff00;
}
</style>
