<template>
  <div class="row">
    <div class="col-sm-12 text-center position-relative">
      <video
        class="video"
        id="video"
        autoplay
        controls
        muted
        playsinline
        ref="video"
      />
    </div>
  </div>
</template>
<script>
import Hls from "hls.js"

export default{
  name: 'ContentVideo',
  data(){
    return{
      encoder: {}
    }
  },
  props:{
    encoderId: Number,
    content: Object
  },
  watch:{
    async content(){
      await this.loadEncoder()
      await this.play()
    },
    async $route(){
      await this.loadEncoder()
      await this.play()
    }
  },
  async mounted(){
    await this.loadEncoder()
    await this.play()
  },
  methods:{
    async loadEncoder(){
      if(!this.encoderId) return
      [this.encoder] = await this.wc.get('v_encoder', `w/id,${this.encoderId}`).catch((err) => {
        console.log(err)
      })
    },
    async play(){
      const video = this.$refs.video
      const url = this.content.content_status_id == 4 ?
        `${this.content.archive_file}/index.m3u8`
        : `${this.encoder.hls_url}/${this.encoder.stream_key}.m3u8`

      if(Hls.isSupported()){
        const hls = new Hls()
        hls.loadSource(url)
        hls.attachMedia(video)

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play()
        })
      } else if(video.canPlayType('application/vnd.apple.mpegurl')){
        console.log(Hls.isSupported())
        video.src = url;
//        video.src = 'https://buckets.dev.ncv.jp/stone/wYksI9LCbnAWEfXcvOPbL/index.m3u8';
        console.log(video);
        video.addEventListener('canplay', () => {
          video.play()
        })
        video.addEventListener('error', (err) => {
          console.log(err)
        })
      }
    }
  }
}
</script>
<style scoped>
.video{
  width: 100%;
}
</style>
