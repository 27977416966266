<template> 
  <v-card outlined class="item-container ma-3">
    <v-card-title class="white--text orange justify-center text-body-1 pa-2">
      主催者メッセージ
    </v-card-title>
    <v-divider />
    <v-list
      class="messageArea overflow-auto"
    >
      <v-list-item 
        v-for="item in messages"
        :key="item.id"
        style="border-bottom: 1px solid #ddd;"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-content 
              v-bind="attrs" 
              v-on="on" 
              class="headline caption pt-2 pb-2"
              >
              <v-list-item-title class="headline">
                <font size="2">
                  [ {{ item.created_at }} ]<br/>
                  {{ item.message }}
                </font>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span>
            {{ item.created_at }} <br/>
            {{ item.message }}
          </span>
        </v-tooltip>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import socket from '@socket/client'

export default {
  name: 'ContentMessages',
  data(){
    return{
      messages: []
    }
  },
  props:{
    contentId: Number
  },
  async mounted(){
    await this.loadMessage()
  },
  watch:{
    contentId:{
      handler: async function(){
        await this.loadMessage()
      }
    }
  },
  methods:{
    async loadMessage(){
      if(!this.contentId) return
      this.messages = await this.wc.get('message', `w/content_id,${this.contentId}:is_enabled,1/o/created_at,desc`).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 960px) { 
  .item-container {
    position: -webkit-sticky; /* safari用 */
    position: sticky;
    margin-bottom: 0;
    top: 0px;
    z-index: 1;
    padding: 0px;
  }
  .messageArea {
    min-height: 422px;
    max-height: 800px;
  }
}
@media screen and (max-width: 960px) { 
  .messageArea {
    min-height: 200px;
  }
}
.headline {
  overflow: ellipsis;
  white-space: nowrap;
}

</style>
